import React from 'react';
import ReactModal from 'react-modal'; // Se puede mantener los estilos, pero convertidos a CSS o Inline Styles
import logo from '../emaran.png';

export const ModalDetalleOrden = ({ result, result2 }) => {
  return (
    <div style={{ padding: '20px' }}> 
      <h2>Detalle por Presentación Final</h2>
      
      {result?.map((producto, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          
          {/* Información del producto */}
          <div style={styles.rowHeader}>
            <div><strong>Código</strong></div>
            <div><strong>Nombre Presentación</strong></div>
            <div><strong>Cantidad Programada</strong></div>
            <div><strong>Cantidad Ingresada</strong></div>
            <div><strong>Es Programado</strong></div>
            <div><strong>Es Terminado</strong></div>
          </div>

          <div style={styles.rowData}>
            <div>{producto.codProd}</div>
            <div>{producto.nomProd}</div>
            <div>{producto.canTotProgProdFin > 0 ? producto.canTotProgProdFin : '-'}</div>
            <div>{producto.canTotIngProdFin > 0 ? producto.canTotIngProdFin : '-'}</div>
            <div>{producto.esProdcProdtProg}</div>
            <div>{producto.esTerIngProFin}</div>
          </div>

          {/* Detalles de Producto */}
          <h5>Detalles de Producto</h5>
          <div style={styles.rowHeader}>
            <div><strong>Código</strong></div>
            <div><strong>Producto de Requisición</strong></div>
            <div><strong>Cant. Ingreso</strong></div>
            <div><strong>Cant. Consumo</strong></div>
            <div><strong>Desme. Fabricación</strong></div>
            <div><strong>Desme. Producción</strong></div>
            <div><strong>Cant. Devuelta</strong></div>
            <div><strong>Diferencia</strong></div>
          </div>

          {producto.detalles?.map((prodDetalle, detalleIndex) => (
            <div
              key={detalleIndex}
              style={{
                ...styles.rowData,
                backgroundColor: detalleIndex % 2 === 0 ? '#d8dade' : '#fff',
              }}
            >
              <div>{prodDetalle.codProd2}</div>
              <div>{prodDetalle.nomProd}</div>
              <div>{prodDetalle.totalCantidad > 0 ? prodDetalle.totalCantidad.toFixed(2) : '-'}</div>
              <div>{prodDetalle.totalusado > 0 ? prodDetalle.totalusado.toFixed(2) : '-'}</div>
              <div>{prodDetalle.devoFabri > 0 ? prodDetalle.devoFabri : '-'}</div>
              <div>{prodDetalle.devoTotal > 0 ? prodDetalle.devoTotal : '-'}</div>
              <div>{prodDetalle.canReqDevDet > 0 ? prodDetalle.canReqDevDet : '-'}</div>
              <div>{prodDetalle.diferencia === 0 ? '-' : prodDetalle.diferencia?.toFixed(2)}</div>
            </div>
          ))}

        </div>
      ))}
    </div>
  )
}

const styles = {
  rowHeader: {
    display: 'flex',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textAlign: 'center',
  },
  rowData: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'space-between',
    textAlign: 'center',
    borderBottom: '1px solid #ddd',
  }
}