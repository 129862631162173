import axios from 'axios'
import config from '../.././../config'

export const getProduccionParaReproceso = async (body) => {
  const domain = config.API_URL
  const path =
    '/calidad/operacion-reproceso-masivo/getStockProdutoPorVencer.php'
  const url = domain + path

  const { data } = await axios.post(url, {
    ...body
  })
  return data
}
