import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { stylesPDF } from './stylePDF';
import { _parseInt } from '../../../utils/functions/ParseInt';
const styles = stylesPDF;

export const DetalleParteEntrada = ({ result }) => {
  return (
    <View style={{ ...styles.section, marginTop: 10 }}>
      {/* Encabezado de la tabla */}
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: '#d8dade',
          padding: 5,
          marginTop: 10,
        }}
      >
        <Text style={{ ...styles.gridContent_p, flex: 1 }}>Cod. Siigo</Text>
        <Text style={{ ...styles.gridContent_p, flex: 1 }}>Cod. Emaprod</Text>
        <Text style={{ ...styles.gridContent_p, flex: 2 }}>Artículo</Text>
        <Text style={{ ...styles.gridContent_p, flex: 1.5 }}>Almacén</Text>
        <Text style={{ ...styles.gridContent_p, flex: 1.5 }}>Cantidad Comprada</Text>
        <Text style={{ ...styles.gridContent_p, flex: 1.5 }}>Cantidad Total</Text>
      </View>

      {/* Filas de detalles */}
      {result.map((item, index) => (
        <View
          key={index}
          style={{
            flexDirection: 'row',
            backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff', // Alternar colores para las filas
            padding: 5,
          }}
        >
          <Text style={{ ...styles.gridContent_p, flex: 1 }}>{item.codProd || 'N/A'}</Text>
          <Text style={{ ...styles.gridContent_p, flex: 1 }}>{item.codProd2 || 'N/A'}</Text>
          <Text style={{ ...styles.gridContent_p, flex: 2 }}>{item.nomProd  || 'N/A'}</Text>
          <Text style={{ ...styles.gridContent_p, flex: 1.5 }}>{item.nomAlm  || 'N/A'}</Text>
          <Text style={{ ...styles.gridContent_p, flex: 1.5 }}>{item.canTotCom  || 'N/A'}</Text>
          <Text style={{ ...styles.gridContent_p, flex: 1.5 }}>{item.canTotEnt  || 'N/A'}</Text>
        </View>
      ))}
    </View>
  );
};