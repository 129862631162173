import { AgregarSalidaAlmacenAux } from './AgregarSalidaAlmacenAux'
// import { ListTransferenciaAlmancen } from './ListTransferenciaAlmancen'
// import { ViewTransferenciaAlmacen } from './ViewTransferenciaAlmacen'

export const RouterAgregarSalidaAlmacenAux = [
  // {
  //   path: '',
  //   element: <ListTransferenciaAlmancen />
  // },
  {
    path: 'crear',
    element: < AgregarSalidaAlmacenAux/>
  },
  // {
  //   path: 'view',
  //   element: <ViewTransferenciaAlmacen />
  // }
]
