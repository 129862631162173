import React, { useEffect, useState } from 'react'
import { FormatDateMYSQL } from '../../../utils/functions/FormatDate'
import { getSalidasVenta } from '../../helpers/orden-pedido/getOrdenPedido'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import FechaPickerMonth from '../../../components/Fechas/FechaPickerMonth'
import { BootstrapDialog } from '../../../components/BootstrapDialog'
import { completSalidaVentasById } from '../../helpers/orden-pedido/completSalidaVentasById'
import {
  CircularProgress,
  Dialog,
  DialogContentText,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material'
import { FilterEstadoRequisicion } from '../../../components/ReferencialesFilters/EstadoRequisicion/FilterEstadoRequisicion'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DescriptionIcon from '@mui/icons-material/Description';

import { getOrdenPedidoPDF } from '../../../almacen/helpers/orden-pedido/getOrdenPedidoPDF'
import { PDFExample } from '../../../almacen/components/pdf-components2/PDFExample'
import { createSaleEmafact } from '../../helpers/orden-pedido/createSaleEmafact'

import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import jsPDF from 'jspdf';

import config from '../../../config'
import axios from 'axios'

// CONFIGURACION DE FEEDBACK
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const ListOrdenPedido = () => {
  const [dataSalidasVenta, setDataSalidasVenta] = useState([])
  const [dataSalidasVentaTemp, setDataSalidasVentaTemp] = useState([])

  // filtros
  const [formState, setformState] = useState({
    fechaInicio: FormatDateMYSQL(),
    fechaFin: FormatDateMYSQL()
  })

  // ESTADOS PARA LA PAGINACIÓN
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // MANEJADORES DE LA PAGINACION
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }



  // ***** FUNCIONES Y STATES PARA FEEDBACK *****
  // ESTADO PARA CONTROLAR EL FEEDBACK
  const [feedbackCreate, setfeedbackCreate] = useState(false)
  const [feedbackMessages, setfeedbackMessages] = useState({
    style_message: '',
    feedback_description_error: ''
  })
  const { style_message, feedback_description_error } = feedbackMessages

  // MANEJADORES DE FEEDBACK
  const handleClickFeeback = () => {
    setfeedbackCreate(true)
  }

  const handleCloseFeedback = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setfeedbackCreate(false)
  }

  const generatePDF = (data) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    console.log(isMobile)
    if (isMobile === true) {
      // // Comportamiento para dispositivos móviles
      // console.log('Generating PDF for mobile...')
      // // Generamos el documento PDF con `@react-pdf/renderer`
      // const content = ReactDOMServer.renderToStaticMarkup(<PDFExample result={data} />);
      // console.log(content)
      // const doc = new jsPDF();
      // doc.html(content, {
      //   callback: (doc) => {
      //     const pdfBlob = doc.output('blob');
      //     const pdfUrl = URL.createObjectURL(pdfBlob);
      //     window.open(pdfUrl, '_blank');
      //   },
      //   x: 10,
      //   y: 10,
      //   width: 190,
      // });
      // Comportamiento para PC
      const windowName = 'Orden de pedido - '.concat(data.numop)
      const newWindow = window.open('', windowName, 'fullscreen=yes')
      ReactDOM.render(<PDFExample result={data} />, newWindow.document.body)
    } else {
      // Comportamiento para PC
      const windowName = 'Orden de pedido - '.concat(data.numop)
      const newWindow = window.open('', windowName, 'fullscreen=yes')
      ReactDOM.render(<PDFExample result={data} />, newWindow.document.body)
    }
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Filtros generales que hacen nuevas consultas
  const handleFechaInicioChange = (newfecEntSto) => {
    const dateFormat = newfecEntSto.split(' ')[0]
    setformState({
      ...formState,
      fechaInicio: dateFormat
    })

    // armamos el body
    const body = {
      ...formState,
      fechaInicio: dateFormat
    }
    obtenerDataSalidasVenta(body)
  }

  // const [feedbackMessages, setfeedbackMessages] = useState({
  //   style_message: '',
  //   feedback_description_error: ''
  // })

  // const handleClickFeeback = () => {
  //   setfeedbackCreate(true)
  // }

  const handleFechaFinChange = (newfecEntSto) => {
    const dateFormat = newfecEntSto.split(' ')[0]
    setformState({
      ...formState,
      fechaFin: dateFormat
    })

    // armamos el body
    const body = {
      ...formState,
      fechaFin: dateFormat
    }
    obtenerDataSalidasVenta(body)
  }

  const [dataFilter, setDataFilter] = useState({
    invSerFac: '',
    invNumFac: '',
    idReqEst: 0,
    customer: ''
  })
  const { invSerFac, invNumFac, customer } = dataFilter

  const handleChangeInputValue = ({ target }) => {
    const { value, name } = target
    setDataFilter({
      ...dataFilter,
      [name]: value
    })
    filter(value, name)
  }

  const handleChangeSelectValue = (value) => {
    const { id, label } = value
    setDataFilter({
      ...dataFilter,
      idReqEst: id
    })
    filter(label, 'idReqEst')
  }

  const handleChangeSelectValue2 = (value) => {
    const { id, label } = value
    setDataFilter({
      ...dataFilter,
      idReqEstRes: id
    })
    filter(label, 'idReqEstRes')
  }

  const filter = (terminoBusqueda, name) => {
    if (name === 'invSerFac') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.NroOP
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
    if (name === 'invNumFac') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.invNumFac
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
    if (name === 'customer') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.NComercial
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
    if (name === 'idReqEst') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.desReqEst
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
    if (name === 'idReqEstRes') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.estadoReqRes
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
  }

  // FUNCION PARA TRAER LA DATA DE REQUISICION MOLIENDA
  const obtenerDataSalidasVenta = async (formState) => {
    openLoader()
    const resultPeticion = await getSalidasVenta(formState)
    // console.log(resultPeticion)
    const { message_error, description_error, result } = resultPeticion
    if (message_error.length === 0) {
      setDataSalidasVenta(result)
      setDataSalidasVentaTemp(result)
    } else {
      alert(description_error)
    }
    closeLoader()
  }
  // ****** MANEJADORES DE PROGRESS LINEAR CON DIALOG ********
  const [openDialog, setOpenDialog] = useState(false)

  // ***** FUNCIONES PARA EL MANEJO DE ACCIONES *****
  const openLoader = () => {
    setOpenDialog(true)
  }
  const closeLoader = () => {
    setOpenDialog(false)
  }

  // FUNCION PARA COMPLETAR LA ENTREGA DEL PEDIDO
  const completarEntrega = async (detalle) => {
    openLoader()
    const formatData = {
      idOP: detalle.id || ""
    }
    console.log(detalle)
    const resultPeticion = await completSalidaVentasById(formatData)
    // console.log(resultPeticion)
    const { message_error, description_error, result } = resultPeticion
    if (message_error.length === 0) {
      obtenerDataSalidasVenta()
    } else {
      alert(description_error)
    }
    closeLoader()
  }

  // ****** TRAEMOS LA DATA DE REQUISICION MOLIENDA ******
  useEffect(() => {
    obtenerDataSalidasVenta()
  }, [])

  useEffect(() => {
    // Llamada directa al script PHP para sincronizar los datos con el contanet
    const domain = config.API_URL;
    const path = 'almacen/orden-pedido/script_get_insert_contanet.php';

    // Asegúrate de pasar los valores correctamente como parte del cuerpo de la solicitud
    axios({
      url: domain + path,
      method: 'GET',
    })
      .then((response) => {
        // console.log('Script ejecutado correctamente:', response);
        // Aquí puedes manejar la respuesta si es necesario
      })
      .catch((error) => {
        // console.error('Error al ejecutar el script:', error);
      })
  }, [])

  // Funcion para generar el comprobante
  const handleButtonPDF = async (id) => {
    try {
      const { result } = await getOrdenPedidoPDF(id)
      // recorremos las requisiciones del proceso de produccion
      // console.log(result)

      generatePDF(result)
    } catch (error) {
      // Mostramos una alerta
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: 'Error al obtener los datos: ' + error
      })
      handleClickFeeback()
    }
  }

  // ESTA FUNCION ES PARA PODER OBTENER LA DATA DE ORDEN DE PEDIDO HASTA QUE SE PUEDA GENERAR LA BOLETA DE VENTA

  const generateSaleEmafact = async (detalle, selectedOption) => {
    try {
      const usuario = JSON.parse(localStorage.getItem('user')) //reutilizable
      // console.log(detalle)
      // console.log(selectedOption)
      const body = {
        userId: usuario.idUsu, // Ajusta según la estructura de 'usuario'
        detalle: detalle,
        tipoComprobante: selectedOption,
      }
      // console.log(body)
      // se genera el comprobante de venta en base a los criterio 
      const resultPeticion = await createSaleEmafact(body)
      const { message_error, description_error, result } = resultPeticion
      // console.log(resultPeticion)
      if (message_error.length === 0) {

        setfeedbackMessages({
          style_message: 'success',
          feedback_description_error: 'La operación se realizó con éxito'
        })
        console.log(feedbackMessages)
        handleClickFeeback()
        obtenerDataSalidasVenta()

      } else {
        alert(description_error)
      }
    } catch (error) {
      // Mostramos una alerta
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: 'Error al obtener los datos: ' + error
      })
      handleClickFeeback()
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex mt-4">
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                <FechaPickerMonth
                  onNewfecEntSto={handleFechaInicioChange}
                  label="Desde"
                />
              </div>
              <div className="col-4">
                <FechaPickerMonth
                  onNewfecEntSto={handleFechaFinChange}
                  label="Hasta"
                />
              </div>
            </div>
          </div>
        </div>
        {/* TABLA DE RESULTADOS */}
        <div className="mt-4">
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      '& th': {
                        color: 'rgba(255, 255, 255)',
                        backgroundColor: '#35572f'
                      }
                    }}
                  >
                    <TableCell align="left" width={50}>
                      <strong>Numero OP</strong>
                      <TextField
                        size="small"
                        type="text"
                        autoComplete='off'
                        onChange={handleChangeInputValue}
                        value={invSerFac}
                        name="invSerFac"
                      />
                    </TableCell>
                    <TableCell align='left' width={180}>
                      <strong>Cliente</strong>
                      <TextField
                        size="small"
                        type="text"
                        autoComplete='off'
                        onChange={handleChangeInputValue}
                        value={customer}
                        name="customer"
                      />
                    </TableCell>
                    <TableCell align="center" width={50}>
                      <strong>Estado salida</strong>
                      <FilterEstadoRequisicion
                        onNewInput={handleChangeSelectValue}
                      />
                    </TableCell>
                    <TableCell align="center" width={50}>
                      <strong>Estado Reserva</strong>
                      <FilterEstadoRequisicion
                        onNewInput={handleChangeSelectValue2}
                      />
                    </TableCell>
                    <TableCell align="center" width={40}>
                      <strong>Afectado</strong>
                    </TableCell>
                    <TableCell align="center" width={40}>
                      <strong>Anulado</strong>
                    </TableCell>
                    <TableCell align="left" width={140}>
                      <strong>Fecha creación</strong>
                    </TableCell>
                    <TableCell align="left" width={100}>
                      <strong>Acciones</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSalidasVentaTemp
                    .filter((row) => row.idReqEst === 3)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '& td': {
                            backgroundColor: '#f5f5f5'
                          }
                        }}
                      >
                        <TableCell align="left">{row.NroOP}</TableCell>
                        <TableCell align="left">{row.NComercial}</TableCell>
                        <TableCell align="center">
                          {row.idReqEst === 1
                            ? (
                              <span className={'badge text-bg-danger'}>
                                {row.desReqEst}
                              </span>
                            )
                            : row.idReqEst === 2
                              ? (
                                <span className={'badge text-bg-warning'}>
                                  {row.desReqEst}
                                </span>
                              )
                              : row.idReqEst === 3
                                ? (
                                  <span className={'badge text-bg-success'}>
                                    {row.desReqEst}
                                  </span>
                                )
                                : (
                                  <span className={'badge text-bg-secondary'}>
                                    {row.desReqEst}
                                  </span>
                                )}
                        </TableCell>
                        <TableCell align="center">
                          {row.idReqEstRes === 1
                            ? (
                              <span className={'badge text-bg-danger'}>
                                {row.estadoReqRes}
                              </span>
                            )
                            : row.idReqEstRes === 2
                              ? (
                                <span className={'badge text-bg-warning'}>
                                  {row.estadoReqRes}
                                </span>
                              )
                              : row.idReqEstRes === 3
                                ? (
                                  <span className={'badge text-bg-success'}>
                                    {row.estadoReqRes}
                                  </span>
                                )
                                : (
                                  <span className={'badge text-bg-secondary'}>
                                    {row.estadoReqRes}
                                  </span>
                                )}
                        </TableCell>
                        <TableCell align="center">
                          {row.fueAfePorDev === 1
                            ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-check-circle-fill"
                                viewBox="0 0 16 16"
                                color="green"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                            )
                            : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-x-circle-fill"
                                viewBox="0 0 16 16"
                                color="red"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                              </svg>
                            )}
                        </TableCell>
                        <TableCell align="center">
                          {row.fueAfePorAnul === 1
                            ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-check-circle-fill"
                                viewBox="0 0 16 16"
                                color="green"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                            )
                            : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-x-circle-fill"
                                viewBox="0 0 16 16"
                                color="red"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                              </svg>
                            )}
                        </TableCell>
                        <TableCell align="left">{row.fecCreOP}</TableCell>
                        <TableCell align="left">
                          <div className="d-flex align-items-center">
                            {/* Botón de Visualización */}
                            <button
                              onClick={() => window.open(`/costeo/orden-pedido/view/${row.id}`, '_blank')}
                              disabled={row.fueAfePorAnul === 1}
                              className="btn btn-primary me-2"
                              data-toggle="modal"
                              title="Ver detalle"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                              </svg>
                            </button>
                            {/* BOTON DE PDF */}
                            <button
                              className="btn btn-danger me-2"
                              title="Imprimir orden"
                              disabled={row.idReqEst != 3}
                              onClick={() => {
                                handleButtonPDF(row.id)
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="currentColor"
                                className="bi bi-file-earmark-pdf-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                <path
                                  fillRule="evenodd"
                                  d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
                                />
                              </svg>
                            </button>

                            {/* Segundo Componente */}
                            <DialogConfirmacionOperacionSalidaVenta
                              detalle={row}
                              disabled={row.fueGenComp === 1}
                              // Configura las propiedades necesarias aquí
                              // disabled={detalle.fueComDet === 1 || detalle.fueResDet === 0}
                              onConfirmOperation={(detalle, selectedOption) => generateSaleEmafact(detalle, selectedOption)}
                            />
                            {/* <div className="dropdown me-2">
                              <button
                                className="btn btn-success dropdown-toggle d-flex align-items-center"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ height: '40px' }}
                                
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
                                  <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z" />
                                  <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" />
                                </svg>

                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>
                                  <button 
                                    className="dropdown-item text-success d-flex align-items-center"
                                    onClick={() => handleButtonPDF(row.id, 'detalleOrden')}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-file-earmark-check me-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zM10.5 1h-5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V5h-3.5a1 1 0 0 1-1-1V1zm-1 7a.5.5 0 0 0-.5.5v.793l-.146-.147a.5.5 0 0 0-.708 0l-.647.646-.353-.353a.5.5 0 0 0-.707 0l-.647.646-.353-.353a.5.5 0 0 0-.707 0l-.146.147V8.5a.5.5 0 0 0-.5-.5H4.5A1.5 1.5 0 0 0 3 9.5V14h10V9.5a1.5 1.5 0 0 0-1.5-1.5H9.5zm-.854-.646a.5.5 0 1 0 .708.708L11 9.707l.146-.147a.5.5 0 1 0-.708-.708L11 8.793l-.646-.646-.146.147zm1.646 4a.5.5 0 1 1 0 1h-1.793a.5.5 0 1 1 0-1h1.793zm0-2a.5.5 0 1 1 0 1h-1.793a.5.5 0 1 1 0-1h1.793z" />
                                    </svg>
                                    Generar Factura
                                  </button>
                                </li>
                                <li>
                                  <button className="dropdown-item text-primary d-flex align-items-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-file-earmark-text me-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zM10.5 1h-5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V5h-3.5a1 1 0 0 1-1-1V1zm-1 7a.5.5 0 0 0-.5.5v.793l-.146-.147a.5.5 0 0 0-.708 0L9 9.793l-.646-.646-.146.147zm.646 3.5a.5.5 0 1 1 0 1h-1.793a.5.5 0 1 1 0-1h1.793zm0-2a.5.5 0 1 1 0 1h-1.793a.5.5 0 1 1 0-1h1.793z" />
                                    </svg>
                                    Generar Boleta
                                  </button>
                                </li>
                              </ul>
                            </div> */}
                            {/* <div className="dropdown me-2">
                              <button
                                className="btn btn-success  d-flex align-items-center"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ height: '40px' }}
                                onClick={toggleModal}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt" viewBox="0 0 16 16">
                                  <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z" />
                                  <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" />
                                </svg>

                              </button>
                            </div> */}

                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* PAGINACION DE LA TABLA */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataSalidasVentaTemp.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/* LOADER CON DIALOG */}
      <Dialog open={openDialog}>
        <DialogTitle>Cargando...</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, espere mientras se procesa la solicitud.
          </DialogContentText>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={feedbackCreate}
        autoHideDuration={6000}
        onClose={handleCloseFeedback}
      >
        <Alert
          onClose={handleCloseFeedback}
          severity={style_message}
          sx={{ width: '100%' }}
        >
          {feedback_description_error}
        </Alert>
      </Snackbar>
    </>
  )
}
const DialogConfirmacionOperacionSalidaVenta = ({
  detalle,
  onConfirmOperation,
  disabled
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const [selectedOption, setSelectedOption] = useState("");

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };


  return (
    <div>
      <IconButton
        aria-label="edit"
        size="large"
        disabled={disabled}
        title="Generar Comprobante"
        color="success"
        onClick={handleClickOpen}
      >
        <DescriptionIcon fontSize="large" />
        {/* <CheckCircleIcon fontSize="inherit" /> */}
      </IconButton>
      <BootstrapDialog
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {/* Salida orden irradiación detalle */}
          GENERACIÓN DE COMPROBANTE
        </DialogTitle>
        <DialogContent dividers>
          <b>¿Está seguro que desea generar el comprobante para la OP?</b>
          <p className="d-block mb-2 mt-2">
            {`Número de Orden de Pedido: ${detalle.NroOP}`}
          </p>
          <div className="mt-3">
            <label htmlFor="tipo-comprobante" className="form-label">
              <b>Seleccione el Tipo</b>
            </label>
            <select
              id="tipo-comprobante"
              className="form-select"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              {/* FALTA LA VERIFICACION DEL DOCUMENTO DE FACTURA PARA ELECCIÓN AUTOMÁTICA */}

              <option value="1">Factura</option>
              <option value="2">Boleta</option>
            </select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            color="error"
            autoFocus
            onClick={() => {
              // terminamos de procesar la salida parcial
              onConfirmOperation(detalle, selectedOption)
              // cerramos el cuadro de dialogo
              handleClose()
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
