import React from 'react'
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  Image
} from '@react-pdf/renderer'
import logo from '../emaran.png'
import { DetalleParteEntrada } from '../../../almacen/components/pdf-components/DetalleParteEntrada'
import { stylesPDF } from './stylePDF'

const styles = stylesPDF

export const PDFExample = ({ result }) => {
  const now = new Date();
  const formattedDate = now.toLocaleDateString(); // Formateo de fecha (ej. "9/16/2024")
  const formattedTime = now.toLocaleTimeString(); // Formateo de hora (ej. "10:35:00 AM")
  return (
    <PDFViewer width="100%" height="100%">
      <Document>
        <Page
          size="A4"
          style={{
            ...styles.page,
            marginTop: 50,
            paddingTop: 10,
            paddingBottom: 75
          }}
        >
          <View style={styles.section}>
            <View style={styles.container}>
              <Image
                src={logo}
                style={{ ...styles.logo, marginTop: -105, marginLeft: 20 }}
              />
            </View>

            <View style={{ ...styles.row, marginTop: -10 }}>
              <View style={styles.column}>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 10,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >Nombres del proveedor: {result[0].nomProv || 'N/A'}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 10,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >Apellidos del proveedor: {result[0].apeProv || 'N/A'}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 10,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >Guia de remisión: {result[0].guiRem || 'N/A'}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 10,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >Factura: {result[0].invoSeri + "-" +result[0].invoNumb || 'N/A'}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 10,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >Fecha de Ingreso: {new Date(result[0].fecEntSto).toISOString().split('T')[0] || 'N/A'}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 10,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >Almacén: {result[0].nomAlm || 'N/A'}
                </Text>
              </View>

              <View style={{ ...styles.row, marginTop: -40 }}>
                <View style={styles.column}>
                  <Text
                    style={{
                      ...styles.content,
                      fontWeight: 'bold',
                      borderRadius: 5,
                      fontSize: 16,
                      marginBottom: 1,
                      backgroundColor: '#d8dbe3',
                      padding: 5,
                      marginRight: 20
                    }}
                  >ENTRADA STOCK ALMACEN
                  </Text>
                  <View
                    style={{
                      ...styles.sectionWithBorder,
                      marginTop: 10,
                      backgroundColor: '#d8dbe3',
                      width: 236,
                      height: 60,
                      borderRadius: 5,
                      marginRight: 20
                    }}
                  >
                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 7,
                        maxWidth: '100%'
                      }}
                    >PARTE DE ENTRADA
                    </Text>
                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 4,
                        maxWidth: '100%',
                        fontSize: 18,
                        fontWeight: 'bold',
                      }}
                    >
                      N°: {result[0].docEntSto || 'N/A'}
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...styles.content,
                      marginLeft: 85,
                      marginTop: -5,
                      maxWidth: '100%',
                      fontWeight: 'bold',
                      fontSize: 7
                    }}
                  >
                    Fecha de generación del documento: {formattedDate} {/*  {formattedTime} */}
                  </Text>
                </View>
              </View>
            </View>



            {/* ///////////////////////////////////////////////////// */}
            {/* PARTE DE CONTENIDO DE LA ORDEN POR PARTE DE ENTRADA (docEntSto) */}
            {/* ///////////////////////////////////////////////////// */}
            <View style={styles.gridContainer}>
                <Text style={{...styles.gridTitle, marginTop: 5 }}>DATOS DE ENTRADA DETALLE</Text>

            {<DetalleParteEntrada result={result} />}
            </View>

            {/* ///////////////////////////////////////////////////// */}
            {/* PARTE INFERIOR DEL DOCUMENTO (OBSERVACIONES Y FIRMAS) */}
            {/* ///////////////////////////////////////////////////// */}
            <View style={{ flex: 1, justifyContent: 'flex-end', marginBottom: 5 }}>
              {/* Sección de Observaciones */}
              <View style={{ marginBottom: 5 }}>
                <Text
                  style={{
                    fontSize: 10,
                    textAlign: 'center',
                    marginBottom: 5,
                  }}
                >
                  OBSERVACIONES
                </Text>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: '#000',
                    borderRadius: 5,
                    height: 80,
                    marginHorizontal: 20,
                  }}
                />
              </View>

              {/* Espacios para las firmas */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 20 }}>
                {/* Primera Firma */}
                <View style={{ alignItems: 'center', flex: 1, marginRight: 10 }}>
                  <Text
                    style={{
                      fontSize: 10,
                      textAlign: 'center',
                      marginBottom: 5,
                    }}
                  >
                    RECIBI CONFORME - ALMACEN
                  </Text>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: '#000',
                      borderRadius: 5,
                      height: 75,
                      width: '100%',
                    }}
                  />
                </View>

                {/* Segunda Firma */}
                <View style={{ alignItems: 'center', flex: 1, marginLeft: 10 }}>
                  <Text
                    style={{
                      fontSize: 10,
                      textAlign: 'center',
                      marginBottom: 5,
                    }}
                  >
                    CONFORMIDAD CONTROL DEL AREA DE CALIDAD
                  </Text>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: '#000',
                      borderRadius: 5,
                      height: 75,
                      width: '100%',
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer >
  )
}
