import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const HomeCalidad = () => {
  // controlar la visualizacion de componentes
  const [show, setShow] = useState(false)

  // verificamos si el usuario tiene acceso a las formulas
  const verifyAdminUser = () => {
    const { idRolUsu } = JSON.parse(localStorage.getItem('user'))
    // si es un usuario administrador
    if (idRolUsu === 1) {
      setShow(true)
    }
  }

  useEffect(() => {
    verifyAdminUser()
  }, [])
  return (
    <>
      <div className="container">
        <h2 className="mt-4 p-2 bg-success-subtle text-emphasis-success">
          Acciones Frecuentes
        </h2>
        <section className="pt-4">
          <div className="container px-lg-5">
            <div className="row gx-lg-5">

              {/* ENTRADAS DE STOCK CALIDAD */}
              {show ? (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Entradas stock</h2>
                      <Link
                        to="/calidad/entradas-stock"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Entradas Evaluadas</h2>
                      <Link
                        to="/calidad/entradas-stock"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {/* OPERACION DE DEVOLUCION */}
              {show && (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Operación devolución</h2>
                      <Link
                        to="/calidad/operacion-devolucion"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* REPORTES DE CALIDAD */}
              {show && (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Reportes calidad</h2>
                      <Link
                        to="/calidad/reportes-calidad"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {/* REQUISICION GENERAL */}
              {show && (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Requisición general</h2>
                      <Link
                        to="/calidad/requisicion-general"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* REPROCESO MASIVO */}
              {show && (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Operacion reproceso Interno</h2>
                      <Link
                        to="/calidad/requisicion-devolucion-masiva"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* Atributos de calidad */}
              {show && (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Crear Atributos de calidad</h2>
                      <Link
                        to="/calidad/atributos-calidad"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* Atributos de calidad */}
              {show && (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Listar solicitudes-reclamo</h2>
                      <Link
                        to="/calidad/operacion-devolucion-solicitud"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* OPERACION DE DESMEDRO */}
              {show && (
                <div className="col-lg-6 col-xxl-4 mb-5">
                  <div className="card bg-light border-0 h-100">
                    <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <div className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4">
                        <i className="bi bi-collection"></i>
                      </div>
                      <h2 className="fs-4 fw-bold">Lote producción</h2>
                      <Link
                        to="/calidad/produccion-lote"
                        className="btn btn-primary"
                      >
                        Ingresar
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default HomeCalidad
