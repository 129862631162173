import React, { useState } from 'react';
import { CustomActionsView } from '../../../components/CustomComponents/CustomActionsView';
import { useCreateOperacionReprocesoMasivo } from '../../hooks/operacion-reproceso-masivo/useCreateOperacionReprocesoMasivo';
import { IconButton, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { BuscarLoteProduccion } from '../../../components/CommonComponents/buscadores/BuscarLoteProduccionReproceso';
import { mostrarMesYAnio } from '../../../utils/functions/FormatDate';
import { getRequisicionIngresosProductosByProduccion } from '../../../almacen/helpers/producto-produccion/getRequisicionIngresosProductosByProduccionStock';
import { CardRequisicionIngresoProductos } from '../../components/reproceso-masivo/CardDetalleReprocesoMasivo';

export const CreateOperacionReprocesoMasivo = () => {
  const {
    informacionLoteProduccion,
    buscarLoteProduccion,
    quitarLoteProduccion
  } = useCreateOperacionReprocesoMasivo();

  const [feedbackMessages, setFeedbackMessages] = useState({
    style_message: '',
    feedback_description_error: '',
  });
  const [openDialog, setOpenDialog] = useState(false);

  const [agregacionesProduccionLote, setAgregacionesProduccionLote] = useState({
    id: 0,
    canLotProd: 0,
    codLotProd: '',
    desEstPro: '',
    desProdTip: '',
    fecVenLotProd: '',
    idProdEst: 0,
    idProdTip: 0,
    idProdt: 0,
    klgLotProd: '',
    nomProd: '',
    prodDetIng: []
  });

  const {
    canLotProd,
    codLotProd,
    desEstPro,
    desProdTip,
    fecVenLotProd,
    klgLotProd,
    nomProd,
    prodDetIng
  } = agregacionesProduccionLote;

  const openLoader = () => {
    setOpenDialog(true);
  };

  const closeLoader = () => {
    setOpenDialog(false);
  };

  const handleClickFeedback = () => {
    console.log('Mostrar feedback:', feedbackMessages);
  };

  // Función para eliminar el detalle de la requisición en la vista
  // Función para eliminar el detalle de la requisición en la vista
  const onDeleteDetalleRequisicionAgregacion = async (detalleId) => {
    // Verifica si el ID es válido
    if (!detalleId) {
      console.error('ID de detalle no válido:', detalleId);
      return;
    }

    // Abrimos el loader
    openLoader();

    try {
      // Filtramos el detalle para eliminarlo de prodDetIng
      const updatedProdDetIng = prodDetIng.filter((requisicion) => requisicion.id !== detalleId);

      // Verifica que se haya actualizado correctamente
      if (updatedProdDetIng.length === prodDetIng.length) {
        console.warn('No se encontró el detalle para eliminar:', detalleId);
      }

      console.log('Detalles actualizados:', updatedProdDetIng); // Confirma el resultado de la filtración

      // Actualizamos el estado con la nueva lista
      setAgregacionesProduccionLote((prevState) => ({
        ...prevState,
        prodDetIng: updatedProdDetIng // Actualizamos el arreglo de detalles
      }));
    } catch (error) {
      console.error('Error al eliminar el detalle:', error);
    } finally {
      // Cerramos el loader
      closeLoader();
    }
  };


  const traerDatosProduccionLoteWithIngresosProducto = async () => {
    const { id } = informacionLoteProduccion;
    if (id) {
      const resultPeticion = await getRequisicionIngresosProductosByProduccion(id);
      const { message_error, description_error, result } = resultPeticion;
      console.log('Resultado de la petición:', result);
      if (!message_error) {
        setAgregacionesProduccionLote(result[0]);
      } else {
        setFeedbackMessages({
          style_message: 'error',
          feedback_description_error: description_error || 'Error desconocido',
        });
        handleClickFeedback();
      }
    }
  };

  const handleQuitarLoteProduccion = () => {
    quitarLoteProduccion();
    setAgregacionesProduccionLote({
      id: 0,
      canLotProd: 0,
      codLotProd: '',
      desEstPro: '',
      desProdTip: '',
      fecVenLotProd: '',
      idProdEst: 0,
      idProdTip: 0,
      idProdt: 0,
      klgLotProd: '',
      nomProd: '',
      prodDetIng: []
    });
  };

  return (
    <>
      <div className='container-flex m-4'>
        <h1 className='text-center fs-2 mt-4 mb-4'>Creación de operación de reproceso masivo</h1>
        <div className='card mb-4'>
          <div className='card-header fw-bold'>Información de lote de producción</div>
          <div className='card-body'>
            <div className='text-center mb-4'>
              {informacionLoteProduccion.idProdc !== null
                ? <div>
                  <span className='badge text-bg-primary p-2'>{`${informacionLoteProduccion.codLotProd} - ${mostrarMesYAnio(informacionLoteProduccion.fecVenLotProd)}`}</span>
                  <IconButton
                    color="error"
                    onClick={handleQuitarLoteProduccion}
                  >
                    <CancelRoundedIcon fontSize="large" />
                  </IconButton>
                </div>
                : <div>
                  <span className='badge text-bg-danger p-2'>Seleccione un lote</span>
                  <BuscarLoteProduccion handleConfirm={buscarLoteProduccion} />
                </div>}
            </div>
            <div className='d-flex flex-row justify-content-center gap-4'>
              <div className='col-3'>
                <label className='form-label fw-semibold'>Lote</label>
                <input type="text" className="form-control" value={`${informacionLoteProduccion.idProdc !== null ? informacionLoteProduccion.codLotProd : 'Sin asignar'}`} disabled />
              </div>
              <div className='col-3'>
                <label className='form-label fw-semibold'>Fecha vencimiento</label>
                <input type="text" className="form-control" value={`${informacionLoteProduccion.idProdc !== null ? informacionLoteProduccion.fecVenLotProd : 'Sin asignar'}`} disabled />
              </div>
              <div className='col-3'>
                <label className='form-label fw-semibold'>Fecha de producción</label>
                <input type="text" className="form-control" value={`${informacionLoteProduccion.idProdc !== null ? informacionLoteProduccion.fecProdIni : 'Sin asignar'}`} disabled />
              </div>
            </div>
            <div className='d-flex flex-row justify-content-center mt-4'>
              <button
                className='btn btn-primary btn-md'
                disabled={informacionLoteProduccion.idProdc === null}
                onClick={traerDatosProduccionLoteWithIngresosProducto}
              >Traer detalle</button>
            </div>
          </div>
        </div>
        <div className='card'>
          <div className='card-header fw-bold'>Detalle de reproceso</div>
          <div className='card-body col-xs-12 col-sm-12 col-md-12'>
            {prodDetIng.length === 0 ? (
              <div className='text-center'>No hay detalles para mostrar</div>
            ) : (
              prodDetIng.map((requisicion) => (
                <CardRequisicionIngresoProductos
                  key={requisicion.id}
                  requisicion={requisicion}
                  onDeleteRequisicionAgregacionDetalle={onDeleteDetalleRequisicionAgregacion} // Confirmar que se pasa correctamente
                />
              ))
            )}
          </div>
        </div>
      </div>
      <CustomActionsView 
      useCreateOperacionReprocesoMasivo={buscarLoteProduccion} 
      onShowCreateButton={true} />
      <Dialog open={openDialog}>
        <DialogTitle>Cargando...</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, espere mientras se procesa la solicitud.
          </DialogContentText>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  );
};
