import axios from 'axios';
import config from '../../../config';

export const updateProduccionLoteEstado = async (id) => {
  const domain = config.API_URL;
  const path = '/produccion/produccion-lote/update_produccion_lote_estado.php';
  const url = domain + path;

  try {
    const response = await axios.post(url, {
      id: id // Enviamos el id como parte del cuerpo de la solicitud en formato JSON
    });
    return response.data;
  } catch (error) {
    console.error("Error al enviar datos:", error);
    throw error;
  }
};
