
import { ListLotesParaReproceso } from './ListLotesParaReproceso'

export const RouterReprocesoMasivo = [
  {
    path: '',
    element: <ListLotesParaReproceso />
  },
  // {
  //   path: 'view/:idOpeDevCal',
  //   element: <ViewOrdenReproceso />
  // }
]
