import React, { useState, useEffect } from 'react'
// IMPORTACIONES PARA TABLE MUI
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
// IMPORTACIONES PARA EL FEEDBACK
import MuiAlert from '@mui/material/Alert'
import { Divider, CircularProgress, Box, Modal, Button, Snackbar, TextField, Typography } from '@mui/material'
// IMPORTACIONES DE PETICIONES
import { getProduccionLote } from './../../helpers/produccion_lote/getProduccionLote'
import { getProduccionSumaryData } from '../../helpers/produccion_lote/getProduccionSumaryData'
import { getProduccionConsolidadoData } from '../../helpers/produccion_lote/getProduccionLoteConsolidado'
import { updateProduccionLoteEstado } from '../../helpers/produccion_lote/updateProduccionLoteEstado'
// IMPORTACIONES DE FILTROS
import { FilterProductoProduccion } from './../../../components/ReferencialesFilters/Producto/FilterProductoProduccion'
import FechaPickerMonth from './../../../components/Fechas/FechaPickerMonth'
import { useForm } from './../../../hooks/useForm'
// IMPORTACIONES DE NAVEGACION
import { Link } from 'react-router-dom'
// COMPONENTES
import ReactDOM from 'react-dom'
import { PDFExample } from '../../components/pdf-components/PDFExample'
import { PDFGeneral } from '../../components/pdf-components2/PDFGeneral'
// ICONOS
import iconProductosFinales from '../../../../src/assets/icons/productos-finales.png'
import iconAgregaciones from '../../../../src/assets/icons/agregaciones.png'
import iconDevoluciones from '../../../../src/assets/icons/devoluciones.png'
import config from '../../../config'
import axios from 'axios'
import { getProduccionWhitProductosFinales } from '../../../almacen/helpers/producto-produccion/getProduccionWhitProductosFinales'
import { ModalGeneral } from '../../components/componentes-lote-produccion/ModalConsolidadoProduccion'
import { ModalDetalleOrden } from '../../components/componentes-lote-produccion/RowModalConsolidadoProduccion'
// importacion del modal 
// import Modal from "./Modal";

const generatePDF2 = (data, data2, data3) => {
  const windowName = data.produccion.numop
  const newWindow = window.open('', windowName, 'fullscreen=yes')
  ReactDOM.render(<PDFGeneral result={data} resultList={data2} resultConsol={data3} />, newWindow.document.body)
}
const generatePDF = (data) => {
  const windowName = data.produccion.numop
  const newWindow = window.open('', windowName, 'fullscreen=yes')
  ReactDOM.render(<PDFExample result={data} />, newWindow.document.body)
}

// CONFIGURACION DE FEEDBACK
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const ListProduccionLote = () => {
  // ESTADOS PARA LOS FILTROS PERSONALIZADOS
  const [dataProduccionLote, setdataProduccionLote] = useState([])
  const [dataProduccionLoteTemp, setdataProduccionLoteTemp] = useState([])

  const [inputs, setInputs] = useState({
    producto: { label: '' },
    provedor: { label: '' },
    estado: { label: '' },
    tipoProduccion: { label: '' },
    estadoInicio: { label: '' },
    numeroOP: '',
    lotePrduccion: ''
  })

  const {
    formState,
    setFormState
  } = useForm({
    fecProdLotIni: '',
    fecProdLotFin: ''
  })

  // ESTADO PARA CONTROLAR EL FEEDBACK
  const [feedbackCreate, setfeedbackCreate] = useState(false)
  const [feedbackMessages, setfeedbackMessages] = useState({
    style_message: '',
    feedback_description_error: ''
  })
  const { style_message, feedback_description_error } = feedbackMessages

  // ESTADOS PARA MOSTRAR EL MODAL CONSOLIDADO DE PRODUCCION
  const [isOpen, setIsOpen] = useState(false) // este es para el modal de produccion consolidados
  const [modalData, setModalData] = useState({ result: null, resultList: null, resultConsol: null })
  // MANEJADORES DE FEEDBACK
  const handleClickFeeback = () => {
    setfeedbackCreate(true)
  }

  const handleCloseFeedback = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setfeedbackCreate(false)
  }

  // Manejadores de cambios
  const handleFormFilter = ({ target }) => {
    const { name, value } = target
    setInputs({
      ...inputs,
      [name]: value
    })
  }

  const onChangeProducto = (obj) => {
    setInputs({
      ...inputs,
      producto: obj
    })
  }

  // Filtros generales que hacen nuevas consultas
  const onChangeDateStartData = (newDate) => {
    const dateFormat = newDate.split(' ')[0]
    setFormState({ ...formState, fecProdLotIni: dateFormat })
    const body = {
      ...formState,
      fecProdLotIni: dateFormat
    }
    obtenerDataProduccionLote(body)
  }

  const onChangeDateEndData = (newDate) => {
    const dateFormat = newDate.split(' ')[0]
    setFormState({ ...formState, fecProdLotFin: dateFormat })
    const body = {
      ...formState,
      fecProdLotFin: dateFormat
    }
    obtenerDataProduccionLote(body)
  }

  // boton de creacion de PDF sumary
  const handleButtonPDF = async (id) => {
    try {
      const { result } = await getProduccionSumaryData(id)
      console.log(result)

      // recorremos las requisiciones del proceso de produccion
      result?.requisiciones?.forEach((req) => {
        // esta variable guardara los totales: {idProdt: cantidad, idProdt: cantidad}
        const totales = {}
        // esta variable guardara los repetidos: {idProdt: {item}, idProdt: {item}}
        const repetidos = {}

        // recorremos el detalle de requisicion
        req.detalles.forEach((item) => {
          // obtenemos id y cantidad
          const { idProdt, canReqDet } = item
          // si aun no existe en totales, lo agregamos
          if (!totales[idProdt]) {
            totales[idProdt] = 0
          } else {
            // caso contrario chancamos repetios[idProdt] cada vez que se repita
            repetidos[idProdt] = { ...item }
          }

          // sumamos el total en totales[idProdt]
          totales[idProdt] += parseFloat(canReqDet)
          // añadimos la propiedad acu (acumulado parcial) al item
          item.acu = totales[idProdt]
        })

        // aqui obtenemos todos los repetidos y le establecemos el acumulado final
        const totalesFinales = Object.keys(repetidos).map((item) => {
          return {
            ...repetidos[item],
            acu: totales[item]
          }
        })

        // agregamos el resumen de productos acumulados
        req.resumenProductos = totalesFinales
        // }
      })
      generatePDF(result)
    } catch (error) {
      // Mostramos una alerta
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: 'Error al obtener los datos: ' + error
      })
      handleClickFeeback()
    }
  }

  // Creacion de reporte consolidado

  const handleButtonPDF2 = async (id) => {
    // const { resultList } = await getProduccionConsolidadoData(id)
    try {
      const { result, resultList, resultConsol } = await getProduccionConsolidadoData(id)
      // recorremos las requisiciones del proceso de produccion

      generatePDF2(result, resultList, resultConsol)
    } catch (error) {
      // Mostramos una alerta
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: 'Error al obtener los datos: ' + error
      })
      handleClickFeeback()
    }
  }

  const modalInformacionConsolidada = async (id) => {
    try {
      const { result, resultList, resultConsol } = await getProduccionConsolidadoData(id)
      console.log(resultConsol)

      // Verificamos que los datos estén disponibles
      if (result && resultList && resultConsol) {
        // Actualizamos el estado con los datos y abrimos el modal
        setModalData({ result, resultList, resultConsol })
        setIsOpen(true)  // Abrimos el modal
      } else {
        // Manejo de error en caso de que los datos estén incompletos
        setfeedbackMessages({
          style_message: 'warning',
          feedback_description_error: 'Datos incompletos para mostrar el modal.'
        });
        handleClickFeeback()
      }
    } catch (error) {
      // Manejo del error
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: 'Error al obtener los datos: ' + error
      })
      handleClickFeeback()
    }
  }
  const closeModal = () => setIsOpen(false);

  // funcion para descargar
  const exportarReporte = (idLotProd) => {
    console.log(idLotProd)
    const domain = config.API_URL
    const path = '/produccion/produccion-lote/generate_reporte_produccion.php'
    axios({
      url: domain + path,
      data: { idLotProd },
      method: 'POST',
      responseType: 'blob' // Importante para recibir datos binarios (Blob)
    })
      .then((response) => {
        // Crear un enlace temporal para descargar el archivo
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = `reporte-produccion-${idLotProd}.xlsx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      })
      .catch((error) => alert('Error al descargar el archivo', error))
  }

  const exportarReporteDevoluciones = (fecProdLotIni, fecProdLotFin) => {
    // console.log(fecProdLotIni, fecProdLotFin); // Verifica que las fechas se pasen correctamente

    const domain = config.API_URL;
    const path = '/produccion/produccion-lote/generate_reporte_devoluciones.php';

    // Asegúrate de pasar los valores correctamente como parte del cuerpo de la solicitud
    axios({
      url: domain + path,
      data: {
        fecProdLotIni: fecProdLotIni,  // Pasa las fechas directamente
        fecProdLotFin: fecProdLotFin
      },
      method: 'POST',
      responseType: 'blob' // Asegúrate de que el servidor devuelva un archivo binario (Blob)
    })
      .then((response) => {
        // Crear un enlace temporal para descargar el archivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `reporte-produccion-${fecProdLotIni}-${fecProdLotFin}.xlsx`; // El nombre del archivo
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Liberar el objeto URL
      })
      .catch((error) => {
        alert('Error al descargar el archivo', error);
      });
  };

  const exportarReporteGeneral = (idLotProd) => {
    console.log(idLotProd)
    const domain = config.API_URL
    const path = '/produccion/produccion-lote/generate_reporte_consolidado.php'
    axios({
      url: domain + path,
      data: { idLotProd },
      method: 'POST',
      responseType: 'blob' // Importante para recibir datos binarios (Blob)
    })
      .then((response) => {
        // Crear un enlace temporal para descargar el archivo
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = `reporte-consolidado-${idLotProd}.xlsx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      })
      .catch((error) => alert('Error al descargar el archivo', error))
  }

  // MODULO PARA LA GENERACION DE REPORTE DE DEVOLUCION EN BASE A FECHAS
  const exportarReporteExcelDevoluciones = (idLotProd) => {
    console.log(idLotProd)
    const domain = config.API_URL
    const path = '/produccion/produccion-lote/generate_reporte_consolidado.php'
    axios({
      url: domain + path,
      data: {
        ...formState.fecProdLotIni,
        ...formState.fecProdLotFin
      },
      method: 'POST',
      responseType: 'blob' // Importante para recibir datos binarios (Blob),
    })
      .then((response) => {
        // Crear un enlace temporal para descargar el archivo
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = `reporte-devoluciones.xlsx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      })
      .catch((error) => alert('Error al descargar el archivo', error))
  }

  // MANEJO DE REPORTE DE CIERRE DE LOTE DE PRODUCCION

  const cerrarLoteProduccion = async (id) => {
    console.log(id)
    // const { resultList } = await getProduccionConsolidadoData(id)
    try {
      const { result, resultList, resultConsol } = await updateProduccionLoteEstado(id)
      // recorremos las requisiciones del proceso de produccion
      console.log(result)
      obtenerDataProduccionLote()
      handleClose()
    } catch (error) {
      // Mostramos una alerta
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: 'Error al obtener los datos: ' + error
      })
      handleClickFeeback()
    }
  }

  // MANEJO DE FILTROS
  useEffect(() => {
    const resultSearch = []
    dataProduccionLote.forEach((data) => {
      if (
        (inputs.estado.label.includes(data.desEstPro) ||
          inputs.estado.label.length === 0) &&
        (inputs.tipoProduccion.label.includes(data.desProdTip) ||
          inputs.tipoProduccion.label.length === 0) &&
        (inputs.producto.label.includes(data.nomProd) ||
          inputs.producto.label.length === 0) &&
        (inputs.estadoInicio.label.includes(data.desProdIniProgEst) ||
          inputs.estadoInicio.label.length === 0) &&
        (data.numop.includes(inputs.numeroOP) || inputs.numeroOP.length === 0) &&
        (data.codLotProd?.includes(inputs.lotePrduccion) ||
          inputs.lotePrduccion.length === 0)
      ) {
        resultSearch.push({ ...data })
      }
    })
    setdataProduccionLoteTemp(resultSearch)
  }, [inputs, dataProduccionLote])

  // reset filtros
  const resetData = () => {
    setdataProduccionLoteTemp(dataProduccionLote)
    setInputs({
      producto: { label: '' },
      provedor: { label: '' },
      estado: { label: '' },
      tipoProduccion: { label: '' },
      estadoInicio: { label: '' },
      numeroOP: '',
      lotePrduccion: ''
    })
  }

  // FUNCION PARA TRAER LA DATA DE REQUISICION MOLIENDA
  const obtenerDataProduccionLote = async (body = {}) => {
    const resultPeticion = await getProduccionLote(body)
    const { message_error, description_error, result } = resultPeticion

    if (message_error.length === 0) {
      // console.log(result)
      setdataProduccionLote(result)
      setdataProduccionLoteTemp(result)
    } else {
      setfeedbackMessages({
        style_message: 'error',
        feedback_description_error: description_error
      })
      handleClickFeeback()
    }
  }

  // TRAEMOS LOS DATOS DE ORDENES DE PRODUCCION
  useEffect(() => {
    obtenerDataProduccionLote()
  }, [])

  const [openModal, setOpenModal] = useState(false); // Estado para manejar la visibilidad del modal
  const [detalle, setDetalle] = useState(null); // Aquí almacenaremos los detalles de la orden seleccionada

  // Usando el hook useState para manejar el estado
  const [proFinProd, setProFinProd] = useState(null);
  // Simulamos una función para obtener el detalle de una orden

  const obtenerDetalleOrden = async (id, codLotProd) => {
    try {
      const resultPeticion = await getProduccionWhitProductosFinales(id);
      const { message_error, description_error, result } = resultPeticion;

      if (result[0]?.proFinProdDet) {
        setProFinProd(result[0]);
        setDetalle(result[0].proFinProdDet); // Establece detalle con el array
      } else {
        console.error('No se encontraron detalles para esta orden');
        setDetalle([]); // Establece un arreglo vacío si no hay detalles
      }

      if (message_error.length === 0) {
        console.log(result);
      } else {
        setfeedbackMessages({
          style_message: 'error',
          feedback_description_error: description_error,
        });
        handleClickFeeback();
      }
    } catch (error) {
      console.error('Error al obtener el detalle de la orden:', error);
      setfeedbackMessages({
        style_message: 'error',
        feedback_description_error: 'Hubo un problema al obtener los detalles.',
      });
      handleClickFeeback();
    }
  }

  const handleShow = async (id, codLotProd) => {
    // Obtenemos el detalle de la orden seleccionada
    await obtenerDetalleOrden(id, codLotProd); // Esperamos que la función termine antes de continuar
    // console.log(detalle)
    setOpenModal(true); // Mostramos el modal
  };

  const handleClose = () => {
    setOpenModal(false); // Cerramos el modal
    setDetalle(null); // Limpiamos el detalle
  };
  const handleShowConsolidado = async (id) => {
    // Obtenemos el detalle de la orden seleccionada
    const { result, resultList, resultConsol } = await getProduccionConsolidadoData(id) // Esperamos que la función termine antes de continuar
    // console.log(detalle)
    setModalData({ result, resultList, resultConsol })
    setIsOpen(true); // Mostramos el modal
  }

  const handleCloseConsolidado = () => {
    setIsOpen(false); // Cerramos el modal
    setModalData(null); // Limpiamos el detalle
  }

  return (
    <>
      <div className="container-fluid">
        {/* FILTROS Y EXPORTACION */}
        <div className="row d-flex mt-4">
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                Desde
                <FechaPickerMonth onNewfecEntSto={onChangeDateStartData} />
              </div>
              <div className="col-4">
                Hasta
                <FechaPickerMonth onNewfecEntSto={onChangeDateEndData} />
              </div>

              <div className="col-2 d-flex align-items-end">
                <button onClick={resetData} className="btn btn-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                </button>
              </div>
              <div className="col-2 d-flex align-items-end" style={{ marginLeft: '-40px' }}>
                <button onClick={() => exportarReporteDevoluciones(formState.fecProdLotIni, formState.fecProdLotFin)} className="btn btn-success d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-excel-fill me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64" />
                  </svg>
                  Devoluciones
                </button>
              </div>

            </div>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center">
            <div className="row">
              <div className="col-6">
                <Link
                  to={'/produccion/produccion-lote/crear'}
                  className="btn btn-primary d-inline-flex justify-content-end align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle-fill me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                  </svg>
                  Agregar
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* TABLA DE RESULTADOS */}
        <div className="mt-4">
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      '& th': {
                        color: 'rgba(96, 96, 96)',
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                  >
                    <TableCell align="left" width={60}>
                      <div className='d-flex flex-column align-items-start'>
                        <span className='fw-bold mb-1'>Lote</span>
                        <TextField
                          name="lotePrduccion"
                          value={inputs.lotePrduccion}
                          onChange={handleFormFilter}
                          type="number"
                          size="small"
                          autoComplete="off"
                          InputProps={{
                            style: {
                              color: 'black',
                              background: 'white'
                            }
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="left" width={90}>
                      <div className='d-flex flex-column align-items-start'>
                        <span className='fw-bold mb-1'>Número OP</span>
                        <TextField
                          name="numeroOP"
                          value={inputs.numeroOP}
                          onChange={handleFormFilter}
                          type="text"
                          size="small"
                          autoComplete="off"
                          InputProps={{
                            style: {
                              textTransform: 'uppercase',
                              color: 'black',
                              background: 'white'
                            },
                            inputProps: {
                              pattern: '[A-Z0-9]*'
                            }
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="left" width={200}>
                      <span className='fw-bold mb-1'>Producto</span>
                      <FilterProductoProduccion
                        onNewInput={onChangeProducto}
                        inputs={inputs}
                      />
                    </TableCell>
                    <TableCell align="center" width={100}>
                      <b>Estado</b>
                    </TableCell>
                    <TableCell align="center" width={70}>
                      <b>Fecha Inicio</b>
                    </TableCell>
                    <TableCell align="center" width={70}>
                      <b>Fecha vencimiento</b>
                    </TableCell>
                    <TableCell align="center" width={100}>
                      <b>Estado Inicio</b>
                    </TableCell>
                    <TableCell align="center" width={100}>
                      <b>Acciones</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataProduccionLoteTemp
                    .map((row, i) => (
                      <TableRow
                        key={row.id}
                      >
                        <TableCell>
                          {row.codLotProd}
                        </TableCell>
                        <TableCell align="left">{row.numop}</TableCell>
                        <TableCell align="left">{row.nomProd}</TableCell>
                        <TableCell align="left">
                          {row.idProdEst === 6 ? (
                            <span className="badge text-bg-success p-1">
                              {row.desEstPro}
                            </span>
                          ) : row.idProdEst === 1 ? (
                            <span className="badge text-bg-warning p-1">
                              {row.desEstPro}
                            </span>
                          ) : (
                            <span className="badge text-bg-danger p-1">
                              {row.desEstPro}
                            </span>
                          )}
                        </TableCell>
                        <TableCell align="center">{row.fecProdIni}</TableCell>
                        <TableCell align="center">{row.fecVenLotProd}</TableCell>
                        <TableCell align="center">
                          {row.desProdIniProgEst}
                        </TableCell>
                        <TableCell align="center">
                          <div className="d-inline-flex justify-content-center">
                            {/* Boton de terminar lote y visualizacion de lotes no terminados */}
                            <div className="dropdown me-2">
                              <button
                                className="btn btn-success dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-files"
                                  viewBox="0 0 16 16"
                                >
                                  {/* <path
                                    fillRule="evenodd"
                                    // d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z"
                                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708"
                                  /> */}
                                  <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                </svg>
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>
                                  <button
                                    className="dropdown-item text-success"
                                    // onClick={() => handleButtonPDF(row.id, 'detalleOrden')}
                                    onClick={() => handleShow(row.id, 'detalleOrden', row.codLotProd)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-eye-fill me-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                    Ver Presentaciones
                                  </button>

                                </li>
                                <li>
                                  <button
                                    className="dropdown-item text-success"
                                    // onClick={() => handleButtonPDF(row.id, 'detalleOrden')}
                                    // onClick={() => modalInformacionConsolidada(row.id, 'detalleOrden')}
                                    // onClick={() => modalInformacionConsolidada(row.id)}
                                    onClick={() => handleShowConsolidado(row.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-eye-fill me-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                    Ver Información
                                  </button>
                                </li>
                              </ul>
                            </div>

                            {/* DESPLEGABLE PARA PDF Y EXCEL */}
                            <div className="dropdown me-2">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-files"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1M3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z"
                                  />
                                </svg>
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>
                                  <button
                                    className="dropdown-item text-danger"
                                    onClick={() => handleButtonPDF(row.id, 'detalleOrden')}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-file-earmark-pdf-fill me-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
                                      />
                                    </svg>
                                    PDF Producción
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item text-secondary"
                                    onClick={() => handleButtonPDF2(row.id, 'detalleOrden')}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-file-earmark-pdf-fill me-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
                                      />
                                    </svg>
                                    PDF Consolidado
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item text-success"
                                    onClick={() => exportarReporteGeneral(row.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-file-earmark-excel-fill me-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64" />
                                    </svg>
                                    Excel consolidado
                                  </button>
                                </li>
                              </ul>
                            </div>
                            {/* OTROS BOTONES */}
                            {/* BOTON DE INGRESO DE PRODUCTOS */}
                            <button
                              className="btn btn-outline-secondary me-2"
                              title="Presentaciones finales"
                              onClick={() => {
                                window.open(
                                  `/almacen/productos-lote/crear?idLotProdc=${row.id}`,
                                  '_blank'
                                )
                              }}
                            >
                              <img
                                alt="Boton ingreso productos"
                                src={iconProductosFinales}
                                height={25}
                                width={25}
                              />
                            </button>
                            {/* BOTON DE DEVOLUCIONES */}
                            <button
                              className="btn btn-outline-secondary me-2"
                              title="Devoluciones"
                              onClick={() => {
                                window.open(
                                  `/almacen/produccion-devoluciones/crear?idLotProdc=${row.id}`,
                                  '_blank'
                                )
                              }}
                            >
                              <img
                                alt="Boton devoluciones"
                                src={iconDevoluciones}
                                height={25}
                                width={25}
                              />
                            </button>
                            {/* BOTON DE AGREGACIONES */}
                            <button
                              className="btn btn-outline-secondary me-2"
                              title="Agregaciones"
                              onClick={() => {
                                window.open(
                                  `/almacen/produccion-agregaciones/crear?idLotProdc=${row.id}`,
                                  '_blank'
                                )
                              }}
                            >
                              <img
                                alt="boton agregaciones"
                                src={iconAgregaciones}
                                height={25}
                                width={25}
                              />
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Modal de Detalle */}
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-detalle-orden"
              aria-describedby="detalle-de-la-orden"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 800,
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  boxShadow: 24,
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  id="modal-detalle-orden"
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: 'bold', mb: 2 }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '750px' }}>
                    <span style={{ flexGrow: 1 }}>
                      {proFinProd?.nomProd ? proFinProd.nomProd : 'No disponible'} - {proFinProd?.codLotProd ? proFinProd.codLotProd : 'No disponible'}
                    </span>

                    {
                      proFinProd && proFinProd.idProdEst === 6 ? (
                        <div style={{ color: 'green', fontWeight: 'bold', border: '2px solid green', borderRadius: '5px', padding: '2px' }}>
                          Lote Cerrado
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          color="success"
                          title='Cerrar lote de producción'
                          disabled={!(detalle && detalle.every((item) => item.esTerIngProFin))} // Deshabilitar el botón si la condición no se cumple
                          onClick={() => {
                            cerrarLoteProduccion(proFinProd.id);
                          }}
                        >
                          Cerra Lote
                        </Button>
                      )
                    }
                  </div>

                </Typography>
                <Divider sx={{ width: '100%', mb: 2 }} />

                <Typography sx={{ textAlign: 'center', mb: 2 }}>
                  {detalle && Array.isArray(detalle) && detalle.length > 0 ? (
                    <div style={{ width: '100%' }}>
                      <Table sx={{ minWidth: 750 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Codigo</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', width: '40%' }}>Presentación</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>Cant. Programada</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>Cant. Ingresada</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Estado</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {detalle.map((item) => (
                            <TableRow key={item.idDetalle}>
                              <TableCell sx={{ width: '10%' }}>{item.codProd2}</TableCell>
                              <TableCell sx={{ width: '50%' }}>{item.nomProd}</TableCell>
                              <TableCell sx={{ width: '20%' }}>{item.canTotProgProdFin}</TableCell>
                              <TableCell sx={{ width: '20%' }}>{item.canTotIngProdFin}</TableCell>
                              <TableCell align="left">
                                {item.esTerIngProFin
                                  ? (
                                    <span className="badge text-bg-success p-1">Finalizado</span>
                                  )
                                  : (
                                    <span className="badge text-bg-warning p-1">Pendiente</span>
                                  )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    <CircularProgress />
                  )}
                </Typography>

                <Divider sx={{ width: '100%', my: 2 }} />

                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  sx={{ width: '100%', mt: 2 }}
                >
                  Cerrar
                </Button>
              </Box>
            </Modal>
            <Modal
              open={isOpen}
              onClose={handleCloseConsolidado}
              aria-labelledby="modal-detalle-orden"
              aria-describedby="detalle-de-la-orden"
            >
              {modalData?.result ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%', // Ajustamos el ancho a un 80% para mayor responsividad
                    maxWidth: '800px', // Limitar el tamaño máximo del modal
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start', // Alineamos a la izquierda
                    overflowY: 'auto', // Permitimos el desplazamiento si el contenido es largo
                    maxHeight: '80vh', // Limitar la altura máxima para evitar desbordes
                  }}
                >
                  <button
                    onClick={handleCloseConsolidado}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      background: 'transparent',
                      border: 'none',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                  >
                    Cerrar
                  </button>

                  <div style={{ width: '100%' }}>
                    {/* Producto Intermedio */}
                    <p><b>Producto Intermedio:</b> {modalData.result.produccion?.nomProd || 'No disponible'}</p>

                    {/* Observaciones */}
                    <p><b>Observaciones:</b></p>
                    <div
                      style={{
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #000',
                        marginBottom: '10px',
                      }}
                    >
                      <p>{modalData.result.produccion?.obsProd || 'No disponible'}</p>
                    </div>

                    {/* Materiales y Embalajes */}
                    <p><b>MATERIALES Y EMBALAJES:</b></p>
                    <div
                      style={{
                        backgroundColor: '#d8dbe3',
                        padding: '10px',
                        borderRadius: '5px',
                        marginBottom: '10px',
                      }}
                    >
                      <p><strong>Tipo de Producción:</strong> {modalData.result.produccion?.desProdTip || 'No disponible'}</p>
                      <p><strong>Número de Lote:</strong> {modalData.result.produccion?.codLotProd || 'No disponible'}</p>
                      <p><strong>Peso Total de Lote:</strong> {parseFloat(modalData.result.produccion?.canLotProd || 0).toFixed(2)} KG</p>
                    </div>

                    {/* Detalle de Orden */}
                    <p><b>Detalle de Orden:</b></p>
                    <ModalDetalleOrden result={modalData.resultList} result2={modalData.resultArray} />
                  </div>
                </Box>
              ) : (
                <div>Loading...</div> // Mostrar un mensaje o un cargador mientras los datos están siendo cargados
              )}
            </Modal>

          </Paper>

        </div>
      </div>

      {/* DIALOG DE SUMARY */}
      {/* FEEDBACK AGREGAR MATERIA PRIMA */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={feedbackCreate}
        autoHideDuration={6000}
        onClose={handleCloseFeedback}
      >
        <Alert
          onClose={handleCloseFeedback}
          severity={style_message}
          sx={{ width: '100%' }}
        >
          <Typography whiteSpace={'pre-line'}>
            {feedback_description_error}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  )
}
