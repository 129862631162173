import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo-oficial.png'
import { useAuth } from '../../hooks/useAuth'
import { getProduccionParaReproceso } from '../helpers/reproceso-masivo/getProduccionParaReproceso'

const NavAlmacen = () => {
  const { logout } = useAuth()
  const [show, setShow] = useState(false)
  const [lotes, setLotes] = useState([])

  const logoutUser = () => {
    logout()
  };

  const verifyAdminUser = () => {
    const { idRolUsu } = JSON.parse(localStorage.getItem('user'))
    if (idRolUsu === 1) {
      setShow(true)
    }
  };

  // Obtener lotes por vencer
  useEffect(() => {
    const fetchLotes = async () => {
      try {
        const lotesPorVencer = await getProduccionParaReproceso()
        const { message_error, description_error, result } = lotesPorVencer
        console.log(result)
        setLotes(result);
      } catch (error) {
        console.error('Error al obtener los lotes por vencer:', error)
      }
    };

    verifyAdminUser()
    fetchLotes()
  }, [])

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand" to={'/almacen'}>
            <img
              src={logo}
              alt="Logo"
              width="70"
              height="60"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* PRODUCCION */}
              <li className="nav-item dropdown">
                <button
                  className="btn btn-lg dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Lote produccion
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      to={'/almacen/lote-produccion'}
                    >
                      Ordenes produccion
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className="dropdown-item"
                      to={'/almacen/produccion-agregaciones'}
                    >
                      Agregaciones
                    </Link>
                  </li> */}
                </ul>
              </li>
              {/* ENTRADAS STOCK */}
              {show && (
                <li className="nav-item dropdown">
                  <button
                    className="btn btn-lg dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Entradas Stock
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to={'/almacen/entradas-stock'}
                      >
                        Administrar
                      </Link>
                    </li>
                    <li>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          window.open('/almacen/entradas-stock/crear', '_blank')
                        }}
                      >
                        Crear
                      </div>
                    </li>
                  </ul>
                </li>
              )}
              {/* REQUISICION MOLIENDA */}
              {show && (
                <li className="nav-item dropdown">
                  <button
                    className="btn btn-lg dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Requisicion molienda
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to={'/almacen/requisicion-molienda'}
                      >
                        Administrar
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              {/* REQUISICION FRESCOS */}

              <li className="nav-item dropdown">
                <button
                  className="btn btn-lg dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Requisicion frescos
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item"
                      to={'/almacen/requisicion-frescos'}
                    >
                      Administrar
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Alerta de lotes por vencer */}
              <li className="nav-item dropdown">
                <button
                  className="btn btn-lg dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-bell"></i> {/* Ícono de campana */}
                  <span className="badge bg-danger ms-1">
                    {lotes.filter(lote => lote.detEstAlm && lote.detEstAlm.length > 0).length}
                  </span> {/* Número dinámico de alertas, solo los que tienen detalles */}
                </button>
                <ul className="dropdown-menu">
                  <li className="dropdown-header">Lotes por vencer:</li>
                  {/* {lotes.length > 0 ? (
                    lotes.map((lote) => (
                      <li key={lote.id}>
                        <Link className="dropdown-item" to={`/almacen/lote/${lote.id}`}>
                          <b>{lote.codLotProd}</b> - vence el <b>{new Date(lote.fecVenLotProd).toLocaleDateString('es-ES')}</b>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="dropdown-item">No hay lotes próximos a vencer</li>
                  )} */}
                  {lotes.length > 0 ? (
                    lotes
                      .filter(lote => lote.detEstAlm && lote.detEstAlm.length > 0)  // Filtra los lotes que tienen detalles
                      .map((lote) => (
                        <li key={lote.id}>
                          <Link className="dropdown-item" to={`/almacen/lote/${lote.id}`}>
                            <b>{lote.codLotProd}</b> - vence el <b>{new Date(lote.fecVenLotProd).toLocaleDateString('es-ES')}</b>
                          </Link>
                        </li>
                      ))
                  ) : (
                    <li className="dropdown-item">No hay lotes próximos a vencer</li>
                  )}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item" to={'/almacen/reproceso-masivo'}>
                      Administrar lotes
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="navbar-nav d-none d-lg-flex ml-2 order-3">
              <li className="nav-item">
                <button onClick={logoutUser} className="nav-link">
                  Cerrar Sesion
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavAlmacen
