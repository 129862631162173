import React, { useEffect, useState } from 'react'
import { FormatDateMYSQL } from '../../../utils/functions/FormatDate'
// import { getSalidasVenta } from '../../helpers/salida-venta/getSalidasVenta'
import { getSalidasVenta } from '../../helpers/orden-pedido/getOrdenPedido'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import FechaPickerMonth from '../../../components/Fechas/FechaPickerMonth'
import { BootstrapDialog } from '../../../components/BootstrapDialog'
import { completSalidaVentasById } from '../../helpers/orden-pedido/completSalidaVentasById'
import {
  CircularProgress,
  Dialog,
  DialogContentText,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material'
import { FilterEstadoRequisicion } from '../../../components/ReferencialesFilters/EstadoRequisicion/FilterEstadoRequisicion'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { getOrdenPedidoPDF } from '../../helpers/orden-pedido/getOrdenPedidoPDF'
import { PDFExample } from '../../components/pdf-components2/PDFExample'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import jsPDF from 'jspdf';

import config from '../../../config'
import axios from 'axios'
import { json } from 'react-router-dom'

export const ListOrdenPedido = () => {
  // SE REALIZA LA GENERACION DEL PDF
  const generatePDF = (data) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    console.log(isMobile)
    if (isMobile === true) {
      // // Comportamiento para dispositivos móviles
      // console.log('Generating PDF for mobile...')
      // // Generamos el documento PDF con `@react-pdf/renderer`
      // const content = ReactDOMServer.renderToStaticMarkup(<PDFExample result={data} />);
      // console.log(content)
      // const doc = new jsPDF();
      // doc.html(content, {
      //   callback: (doc) => {
      //     const pdfBlob = doc.output('blob');
      //     const pdfUrl = URL.createObjectURL(pdfBlob);
      //     window.open(pdfUrl, '_blank');
      //   },
      //   x: 10,
      //   y: 10,
      //   width: 190,
      // });
      // Comportamiento para PC
      const windowName = 'Orden de pedido - '.concat(data.numop)
      const newWindow = window.open('', windowName, 'fullscreen=yes')
      ReactDOM.render(<PDFExample result={data} />, newWindow.document.body)
    } else {
      // Comportamiento para PC
      const windowName = 'Orden de pedido - '.concat(data.numop)
      const newWindow = window.open('', windowName, 'fullscreen=yes')
      ReactDOM.render(<PDFExample result={data} />, newWindow.document.body)
    }
  }

  const [dataSalidasVenta, setDataSalidasVenta] = useState([])
  const [dataSalidasVentaTemp, setDataSalidasVentaTemp] = useState([])

  // filtros
  const [formState, setformState] = useState({
    fechaInicio: FormatDateMYSQL(),
    fechaFin: FormatDateMYSQL()
  })
  const [feedbackCreate, setfeedbackCreate] = useState(false)
  const [feedbackMessages, setfeedbackMessages] = useState({
    style_message: '',
    feedback_description_error: ''
  })
  const handleClickFeeback = () => {
    setfeedbackCreate(true)
  }
  // ESTADOS PARA LA PAGINACIÓN
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // MANEJADORES DE LA PAGINACION
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Filtros generales que hacen nuevas consultas
  const handleFechaInicioChange = (newfecEntSto) => {
    const dateFormat = newfecEntSto.split(' ')[0]
    setformState({
      ...formState,
      fechaInicio: dateFormat
    })

    // armamos el body
    const body = {
      ...formState,
      fechaInicio: dateFormat
    }
    obtenerDataSalidasVenta(body)
  }

  const handleFechaFinChange = (newfecEntSto) => {
    const dateFormat = newfecEntSto.split(' ')[0]
    setformState({
      ...formState,
      fechaFin: dateFormat
    })

    // armamos el body
    const body = {
      ...formState,
      fechaFin: dateFormat
    }
    obtenerDataSalidasVenta(body)
  }

  const [dataFilter, setDataFilter] = useState({
    invSerFac: '',
    invNumFac: '',
    idReqEst: 0,
    customer: ''
  })
  const { invSerFac, invNumFac, customer } = dataFilter

  const handleChangeInputValue = ({ target }) => {
    const { value, name } = target
    setDataFilter({
      ...dataFilter,
      [name]: value
    })

    filter(value, name)
  }

  const handleChangeSelectValue = (value) => {
    const { id, label } = value
    setDataFilter({
      ...dataFilter,
      idReqEst: id
    })
    filter(label, 'idReqEst')
  }

  const handleChangeSelectValue2 = (value) => {
    const { id, label } = value
    setDataFilter({
      ...dataFilter,
      idReqEstRes: id
    })
    filter(label, 'idReqEstRes')
  }

  const filter = (terminoBusqueda, name) => {
    if (name === 'invSerFac') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.NroOP
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
    if (name === 'invNumFac') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.invNumFac
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
    if (name === 'customer') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.NComercial
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
    if (name === 'idReqEst') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.desReqEst
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
    if (name === 'idReqEstRes') {
      const resultadoBusqueda = dataSalidasVenta.filter((element) => {
        if (
          element.estadoReqRes
            .toString()
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
        ) {
          return element
        } else {
          return false
        }
      })
      setDataSalidasVentaTemp(resultadoBusqueda)
    }
  }

  // FUNCION PARA TRAER LA DATA DE REQUISICION MOLIENDA
  const obtenerDataSalidasVenta = async (formState) => {
    openLoader()
    const resultPeticion = await getSalidasVenta(formState)

    const { message_error, description_error, result } = resultPeticion
    if (message_error.length === 0) {
      setDataSalidasVenta(result)
      setDataSalidasVentaTemp(result)
    } else {
      alert(description_error)
    }
    closeLoader()
  }
  // ****** MANEJADORES DE PROGRESS LINEAR CON DIALOG ********
  const [openDialog, setOpenDialog] = useState(false)

  // ***** FUNCIONES PARA EL MANEJO DE ACCIONES *****
  const openLoader = () => {
    setOpenDialog(true)
  }
  const closeLoader = () => {
    setOpenDialog(false)
  }

  // FUNCION PARA COMPLETAR LA ENTREGA DEL PEDIDO
  const completarEntrega = async (detalle) => {
    openLoader()
    const formatData = {
      idOP: detalle.id || "",
      usu: JSON.parse(localStorage.getItem('user'))
    }
    // console.log(detalle)
    const resultPeticion = await completSalidaVentasById(formatData)
    console.log(resultPeticion)
    const { message_error, description_error, result } = resultPeticion
    if (message_error.length === 0) {
      obtenerDataSalidasVenta()
    } else {
      alert(description_error)
    }
    closeLoader()
  }

  // ****** TRAEMOS LA DATA DE ORDENES DE PEDIDO ******
  useEffect(() => {

    obtenerDataSalidasVenta()
  }, [])

  useEffect(() => {
    // Llamada directa al script PHP para sincronizar los datos con el contanet
    const domain = config.API_URL;
    const path = 'almacen/orden-pedido/script_get_insert_contanet.php';

    // Asegúrate de pasar los valores correctamente como parte del cuerpo de la solicitud
    axios({
      url: domain + path,
      method: 'GET',
    })
      .then((response) => {
        // console.log('Script ejecutado correctamente:', response);
        // Aquí puedes manejar la respuesta si es necesario
      })
      .catch((error) => {
        // console.error('Error al ejecutar el script:', error);
      })
  }, [])

  const handleButtonPDF = async (id) => {
    try {
      const { result } = await getOrdenPedidoPDF(id)
      // recorremos las requisiciones del proceso de produccion
      console.log(result)

      generatePDF(result)
    } catch (error) {
      // Mostramos una alerta
      setfeedbackMessages({
        style_message: 'warning',
        feedback_description_error: 'Error al obtener los datos: ' + error
      })
      handleClickFeeback()
    }
  }

  const resetData = () => {
    // le seteamos la data
    // setDataFilter({
    //   invSerFac: '',   // Resetea "Numero OP"
    //   invNumFac: '',   // Resetea "Número Factura"
    //   idReqEst: 0,     // Resetea "Estado Salida"
    //   customer: '',     // Resetea "Cliente"
    //   idReqEstRes: 0 
    // })
    // console.log(dataFilter)
    // setDataSalidasVentaTemp(dataSalidasVenta)

    window.location.reload()
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex mt-4">
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                <FechaPickerMonth
                  onNewfecEntSto={handleFechaInicioChange}
                  label="Desde"
                />
              </div>
              <div className="col-4">
                <FechaPickerMonth
                  onNewfecEntSto={handleFechaFinChange}
                  label="Hasta"
                />
              </div>
              <div
                className="col-3 d-flex align-items-end"
                style={
                  {
                    // border: "1px solid black",
                  }
                }
              >
                <button style={{ height: "100%" }} onClick={resetData} className="btn btn-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="currentColor"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* TABLA DE RESULTADOS */}
        <div className="mt-4">
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      '& th': {
                        color: 'rgba(255, 255, 255)',
                        backgroundColor: '#35572f'
                      }
                    }}
                  >
                    <TableCell align="left" width={50}>
                      <strong>Numero OP</strong>
                      <TextField
                        size="small"
                        type="text"
                        autoComplete='off'
                        onChange={handleChangeInputValue}
                        value={invSerFac}
                        name="invSerFac"
                      />
                    </TableCell>
                    <TableCell align='left' width={180}>
                      <strong>Cliente</strong>
                      <TextField
                        size="small"
                        type="text"
                        autoComplete='off'
                        onChange={handleChangeInputValue}
                        value={customer}
                        name="customer"
                      />
                    </TableCell>
                    <TableCell align="center" width={50}>
                      <strong>Estado salida</strong>
                      <FilterEstadoRequisicion
                        onNewInput={handleChangeSelectValue}
                      />
                    </TableCell>
                    <TableCell align="center" width={50}>
                      <strong>Estado Reserva</strong>
                      <FilterEstadoRequisicion
                        onNewInput={handleChangeSelectValue2}
                      />
                    </TableCell>
                    <TableCell align="center" width={40}>
                      <strong>Afectado</strong>
                    </TableCell>
                    <TableCell align="center" width={40}>
                      <strong>Anulado</strong>
                    </TableCell>
                    <TableCell align="left" width={140}>
                      <strong>Fecha creación</strong>
                    </TableCell>
                    <TableCell align="left" width={100}>
                      <strong>Acciones</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSalidasVentaTemp
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '& td': {
                            backgroundColor: '#f5f5f5'
                          }
                        }}
                      >
                        <TableCell align="left">{row.NroOP}</TableCell>
                        <TableCell align="left">{row.NComercial}</TableCell>
                        <TableCell align="center">
                          {row.idReqEst === 1
                            ? (
                              <span className={'badge text-bg-danger'}>
                                {row.desReqEst}
                              </span>
                            )
                            : row.idReqEst === 2
                              ? (
                                <span className={'badge text-bg-warning'}>
                                  {row.desReqEst}
                                </span>
                              )
                              : row.idReqEst === 3
                                ? (
                                  <span className={'badge text-bg-success'}>
                                    {row.desReqEst}
                                  </span>
                                )
                                : (
                                  <span className={'badge text-bg-secondary'}>
                                    {row.desReqEst}
                                  </span>
                                )}
                        </TableCell>
                        <TableCell align="center">
                          {row.idReqEstRes === 1
                            ? (
                              <span className={'badge text-bg-danger'}>
                                {row.estadoReqRes}
                              </span>
                            )
                            : row.idReqEstRes === 2
                              ? (
                                <span className={'badge text-bg-warning'}>
                                  {row.estadoReqRes}
                                </span>
                              )
                              : row.idReqEstRes === 3
                                ? (
                                  <span className={'badge text-bg-success'}>
                                    {row.estadoReqRes}
                                  </span>
                                )
                                : (
                                  <span className={'badge text-bg-secondary'}>
                                    {row.estadoReqRes}
                                  </span>
                                )}
                        </TableCell>
                        <TableCell align="center">
                          {row.fueAfePorDev === 1
                            ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-check-circle-fill"
                                viewBox="0 0 16 16"
                                color="green"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                            )
                            : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-x-circle-fill"
                                viewBox="0 0 16 16"
                                color="red"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                              </svg>
                            )}
                        </TableCell>
                        <TableCell align="center">
                          {row.fueAfePorAnul === 1
                            ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-check-circle-fill"
                                viewBox="0 0 16 16"
                                color="green"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                            )
                            : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-x-circle-fill"
                                viewBox="0 0 16 16"
                                color="red"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                              </svg>
                            )}
                        </TableCell>
                        <TableCell align="left">{row.fecCreOP}</TableCell>
                        <TableCell align="left">
                          <div className="d-flex align-items-center">
                            {/* Botón de Visualización */}
                            <button
                              onClick={() => window.open(`/almacen/orden-pedido/view/${row.id}`, '_blank')}
                              disabled={row.fueAfePorAnul === 1}
                              className="btn btn-primary me-2"
                              data-toggle="modal"
                              title="Ver detalle"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                              </svg>
                            </button>

                            {/* Segundo Componente */}
                            <DialogConfirmacionOperacionSalidaVenta
                              detalle={row}
                              disabled={row.fueAfePorRes === 0 || row.idReqEst == 3 || row.fueAfePorAnul === 1}
                              // Configura las propiedades necesarias aquí
                              // disabled={detalle.fueComDet === 1 || detalle.fueResDet === 0}
                              onConfirmOperation={(detalle) => completarEntrega(detalle)}
                            />
                            {/* BOTON DE PDF */}
                            <button
                              className="btn btn-success me-2"
                              title="Imprimir orden"
                              disabled={row.idReqEst != 3}
                              onClick={() => {
                                handleButtonPDF(row.id)
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-file-earmark-pdf-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                <path
                                  fillRule="evenodd"
                                  d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
                                />
                              </svg>
                            </button>
                          </div>
                        </TableCell>


                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* PAGINACION DE LA TABLA */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataSalidasVentaTemp.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/* LOADER CON DIALOG */}
      <Dialog open={openDialog}>
        <DialogTitle>Cargando...</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, espere mientras se procesa la solicitud.
          </DialogContentText>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  )
}
const DialogConfirmacionOperacionSalidaVenta = ({
  detalle,
  onConfirmOperation,
  disabled
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <IconButton
        aria-label="edit"
        size="large"
        disabled={disabled}
        title="Confirmar salida"
        color="success"
        onClick={handleClickOpen}
      >
        <CheckCircleIcon fontSize="inherit" />
      </IconButton>
      <BootstrapDialog
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {/* Salida orden irradiación detalle */}
          GENERAR SALIDA DE ORDEN DE PEDIDO
        </DialogTitle>
        <DialogContent dividers>
          {/* <b>¿Estas seguro de realizar la salida del detalle?</b> */}
          <b>¿Estas seguro de realizar la salida de esta Orden de Pedido?</b>
          <p className="d-block mb-2 mt-2">
            {`Numero de Orden de Pedido: `}<strong>{detalle.NroOP}</strong>
          </p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            color="error"
            autoFocus
            onClick={() => {
              // terminamos de procesar la salida parcial
              onConfirmOperation(detalle)
              // cerramos el cuadro de dialogo
              handleClose()
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
